<template>
    <HeaderComponent></HeaderComponent>
    <MenuBarComponent></MenuBarComponent>
    <div id="background-area">
        <div class="content-area">
            <div id="profile-area">
                <div id="profile-text">
                    <div>こんにちは、<b>minao</b>です。</div>
                    <br>
                    <div>こちらは日々の活動を記録するための趣味のホームページです。</div>
                    <div>気が向いたら更新し、ゆるく運営したいと思います。</div>
                    <br>
                    <br>
                    <b>minaoとは</b>
                    <div>    30歳ぐらいのおっさん</div>
                    <br>
                    <b>趣味</b>
                    <div>    旅、お絵かき、アニメ、ダンレボ、プログラミング</div>
                    <br>
                    <b>このブログについて</b>
                    <div>    自作しています。</div>
                    <div>    まだ殺風景なサイトですが、そのうち機能やデザインなどアップデートしていきたいと思います。</div>
                </div>
            </div>
        </div>
    </div>
    <FooterComponent></FooterComponent>
</template>

<script>
    import HeaderComponent from "@/components/header.vue";
    import MenuBarComponent from "@/components/menuBar.vue";
    import FooterComponent from "@/components/footer.vue";
    export default {
        name: "HomeView",
        components: {
            HeaderComponent,
            MenuBarComponent,
            FooterComponent
        }
    };
</script>

<style scoped lang="scss">
    @import "@/scss/views/home.scss";
</style>