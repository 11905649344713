import {createRouter, createWebHistory} from "vue-router"

import HomeView from "@/views/home.vue"
import BlogView from "@/views/blog/blog.vue"
import BlogArticleView from "@/views/blog/article.vue"
import ErrorView from "@/views/error.vue"

const routes = [
  {path: "/", name: "home", component: HomeView},
  {path: "/blog", name: "blog", component: BlogView},
  {path: "/blog/article/:article_id", name: "blog_article", component: BlogArticleView},
  {path: "/error", name: "error", component: ErrorView},
  {path: "/:catchAll(.*)", component: ErrorView}
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// router.push()による遷移前に発火する処理
router.beforeEach((to, from) => {
    // router.push()での同一パス遷移では画面がリロードされないため別途画面遷移処理を実行
    if((from.path === to.path) && (from.fullPath !== to.fullPath)) {
      window.location = to.fullPath;
    }
})

export default router
