<template>
    <div id="app">
        <router-view>
        </router-view>
    </div>
</template>

<script>
    import { onMounted } from "vue";
    import "@/scss/app.scss";

    import titleUtility from "@/js/common/titleUtility.js";

    export default {
        name: "app",
        setup() {
            onMounted(() => {
                // 画面タイトルのデフォルト値設定
                document.title = titleUtility.getPageTitle();

                // viewport設定（スマホ表示のレイアウト崩れ対応）
                let meta_element = document.createElement("meta");
                meta_element.name = "viewport";
                meta_element.content = "initial-scale=0.3";
                document.head.appendChild(meta_element);
            })
        }
    };
</script>