<template>
    <div id="category-list-area" class="content-area">
        <div id="category-title-area">
            <div id="category-title-text">
                カテゴリ
            </div>
        </div>
        <div id="category-names-area">
            <div
                v-for="category in categories"
                class="category-name-area link"
                v-on:click="moveToFilteredArticlesByCategory(category_id=category.id)"
            >
                <div class="category-name-text">
                    {{category.name}} ({{category.count}})
                </div>
            </div>
        </div>
    </div>
    <div id="yearmonth-list-area" class="content-area">
        <div id="yearmonth-title-area">
            <div id="yearmonth-title-text">
                月別アーカイブ
            </div>
        </div>
        <div id="yearmonth-names-area">
            <div
                v-for="yearmonth in yearmonths"
                class=""
                v-on:click="moveToFilteredArticlesByYearMonth(year=yearmonth.year, month=yearmonth.month)"
            >
                <div class="yearmonth-name-area link">
                    <div class="yearmonth-name-text">
                        {{yearmonth.year}}/{{yearmonth.month}} ({{yearmonth.count}})
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from '@/router/index.js';

    export default {
        name: 'BlogSideMenuComponent',
        props: {
            categories: Array,
            yearmonths: Array
        },
        setup(props) {
            //-----------------------------------------
            // メソッド（APIコールなし・テンプレート内使用あり）
            //-----------------------------------------
            function moveToFilteredArticlesByCategory(category_id) {
                let query_params = {
                    category_id: category_id,
                };
                router.push({name: "blog", query: query_params});
            }

            function moveToFilteredArticlesByYearMonth(year, month) {
                let query_params = {
                    year: year,
                    month: month
                };
                router.push({name: "blog", query: query_params});
            }

            function getYearmonthsByCategory(category_id) {
                let article_count = 0;
                // props更新前後で2回実行される。1回目はprops.article_countは{}のため、2回目のみ処理実行。
                if ("category" in props.article_count) {
                    const category = props.article_count["category"].find(
                        category => category.category_id === category_id
                    );
                    if (category) {
                        article_count = category["count"];
                    }
                }
                return article_count;
            }

            // -----------------------------------------
            // テンプレートへの返り値
            // -----------------------------------------
            return {
                moveToFilteredArticlesByCategory, moveToFilteredArticlesByYearMonth, getYearmonthsByCategory
            };
        }
    }
</script>

<style scoped lang="scss">
    @import '@/scss/components/blog/sideMenu.scss';
</style>
