<template>
    <HeaderComponent></HeaderComponent>
    <MenuBarComponent></MenuBarComponent>
    <div id="background-area">
        <div id="error-message-area">
            <div id="error-message-text">
                お探しのページは見つかりません。
            </div>
        </div>
    </div>
    <FooterComponent></FooterComponent>
</template>

<script>
    import HeaderComponent from "@/components/header.vue";
    import MenuBarComponent from "@/components/menuBar.vue";
    import FooterComponent from "@/components/footer.vue";
    export default {
        name: "ErrorView",
        components: {
            HeaderComponent,
            MenuBarComponent,
            FooterComponent
        }
    };
</script>

<style scoped lang="scss">
    @import "@/scss/views/error.scss";
</style>