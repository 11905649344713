//-----------------------------------------
// 内部メソッド
//-----------------------------------------
function padZeroToDatetimeValue(value) {
    return value.toString().padStart(2, "0");
}


//-----------------------------------------
// 外部メソッド
//-----------------------------------------
function getDatetimeString(input_datetime) {
    const datetime = new Date(input_datetime);

    const year = padZeroToDatetimeValue(datetime.getFullYear());
    const month = padZeroToDatetimeValue(datetime.getMonth() + 1);  // getMonth()のみ0始まり
    const day = padZeroToDatetimeValue(datetime.getDate());
    const hour = padZeroToDatetimeValue(datetime.getHours());
    const minute = padZeroToDatetimeValue(datetime.getMinutes());

    return `${year}/${month}/${day} ${hour}:${minute}`;
}

function getDateString(input_datetime) {
    const datetime = new Date(input_datetime);

    const year = padZeroToDatetimeValue(datetime.getFullYear());
    const month = padZeroToDatetimeValue(datetime.getMonth() + 1);  // getMonth()のみ0始まり
    const day = padZeroToDatetimeValue(datetime.getDate());

    return `${year}/${month}/${day}`;
}

const datetimeUtility = {
    getDatetimeString,
    getDateString
};
export default datetimeUtility;