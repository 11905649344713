<template>
    <div id="header">
        <div id="homepage-title-area">
            <div id="homepage-title-text">
                {{HOMEPAGE_TITLE}}
            </div>
        </div>
    </div>
</template>

<script>
    import appConfigs from "@/configs/common/appConfig.js"
    export default {
        name: "HeaderComponent",
        setup() {
            return {
                HOMEPAGE_TITLE: appConfigs.HOMEPAGE_TITLE
            };
        }
    };
</script>

<style scoped lang="scss">
    @import "@/scss/components/header.scss";
</style>