function excludeEmptyKeys(object) {
    for(const key in object) {
        if([null, undefined].includes(object[key])) {
            delete object[key];
        }
    }
}

const objectUtility = {
    excludeEmptyKeys,
};
export default objectUtility;