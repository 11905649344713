<template>
    <div id="footer">
        <div id="copyright-area">
            <div id="copyright-text">
                © minao. All Rights Reserved.
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FooterComponent"
    };
</script>

<style scoped lang="scss">
    @import "@/scss/components/footer.scss";
</style>