<template>
    <nav id="menubar-area">
        <div id="menubar-items-area">
            <div class="menubar-item link" v-on:click="moveToHome()">
                <div class="menubar-text">HOME</div>
            </div>
            <div class="menubar-item link" v-on:click="moveToBlog()">
                <div class="menubar-text">BLOG</div>
            </div>
        </div>
    </nav>
</template>

<script>
    import router from "@/router/index.js";

    export default {
        name: "MenuBarComponent",
        setup() {
            //-----------------------------------------
            // メソッド（APIコールなし・テンプレート内使用あり）
            //-----------------------------------------
            function moveToHome() {
                router.push({name: "home"});
            }

            function moveToBlog() {
                router.push({name: "blog"});
            }

            // -----------------------------------------
            // テンプレートへの返り値
            // -----------------------------------------
            return {
                moveToHome, moveToBlog
            };
        }
    };
</script>

<style scoped lang="scss">
    @import "@/scss/components/menuBar.scss";
</style>