const ARTICLE_API_PATH = "api/internal/blog/article";
const CATEGORY_API_PATH = "api/internal/blog/category";
const YEARMONTH_API_PATH = "api/internal/blog/yearmonth";
const FILE_API_PATH = "api/internal/blog/file";

const blogPathConfig = {
    ARTICLE_API_PATH,
    CATEGORY_API_PATH,
    YEARMONTH_API_PATH,
    FILE_API_PATH,
};
export default blogPathConfig;