<template>
    <HeaderComponent></HeaderComponent>
    <MenuBarComponent></MenuBarComponent>
    <div id="background-area">
        <div id="contents-area">
            <div id="side-menu-area">
                <BlogSideMenuComponent
                    v-bind:categories="categories"
                    v-bind:yearmonths="yearmonths"
                >
                </BlogSideMenuComponent>
            </div>
            <div id="article-area">
                <div class="content-area">
                    <div id="article-title-area">
                        <div id="article-title-text">
                            {{article.title}}
                        </div>
                    </div>
                    <div id="article-text-area">
                        <div id="article-text-text" v-html="fixed_article_text">
                        </div>
                    </div>
                    <div id="article-info-area">
                        <div id="article-category-name-area">
                            <div id="article-category-name-text">
                                {{getCategoryName(article.category_id)}}
                            </div>
                        </div>
                        <div id="article-datetime-area">
                            <div class="article-created-datetime-area">
                                <div class="article-created-datetime-text">
                                    作成日時：{{getDatetimeString(article.created_at)}}
                                </div>
                            </div>
                            <div
                                v-if="article.updated_at"
                                class="article-updated-datetime-area"
                            >
                                <div class="article-updated-datetime-text">
                                    更新日時：{{getDatetimeString(article.updated_at)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterComponent></FooterComponent>
</template>

<script>
    import { ref, onMounted } from "vue";
    import { useRoute } from "vue-router";
    import axios from "axios";

    import HeaderComponent from "@/components/header.vue";
    import MenuBarComponent from "@/components/menuBar.vue";
    import FooterComponent from "@/components/footer.vue";
    import BlogSideMenuComponent from "@/components/blog/sideMenu.vue";

    import router from "@/router/index.js";
    import titleUtility from "@/js/common/titleUtility.js";
    import httpUtility from "@/js/common/httpUtility.js";
    import datetimeUtility from "@/js/common/datetimeUtility.js";

    import blogPathConfig from "@/configs/blog/pathConfig.js";

    export default {
        name: "BlogArticleView",
        components: {
            HeaderComponent,
            MenuBarComponent,
            FooterComponent,
            BlogSideMenuComponent
        },
        setup() {
            // -----------------------------------------
            // 定数
            // -----------------------------------------

            // -----------------------------------------
            // 変数
            // -----------------------------------------
            let article = ref({});
            let categories = ref([]);
            let yearmonths = ref([]);
            let fixed_article_text = ref(null);
            let url_query_params = null;

            // -----------------------------------------
            // 画面読み込み時処理
            // -----------------------------------------
            onMounted(() => {
                url_query_params = new URLSearchParams(window.location.search);

                getArticle();
                getYearmonths();
                getCategories();
            })

            //-----------------------------------------
            // メソッド（APIコールなし・テンプレート内使用なし）
            //-----------------------------------------
            function fixArticleText(text) {
                const dom_parser = new DOMParser();
                let text_html = dom_parser.parseFromString(text, "text/html");

                // text内のimg要素にファイルURLを設定する
                let img_elements = text_html.querySelectorAll("img");
                for(let img_element of img_elements) {
                    const file_id = img_element.getAttribute("file_id");
                    img_element.setAttribute(
                        "src",
                        `${httpUtility.getBackendAPIUrl(blogPathConfig.FILE_API_PATH)}/${file_id}`
                    );
                }

                return text_html.body.innerHTML
            }

            function sortBySort(objects) {
                // sort昇順にソート
                objects.sort((a, b) => {
                    let compare_result = a.sort - b.sort;
                    if (!a.sort) compare_result = 1;
                    return compare_result;
                })
            }

            function sortByYearmonth(objects) {
                // year > monthの優先度で降順にソート
                objects.sort((a, b) => {
                    let compare_result = b.year - a.year;
                    if (compare_result === 0) compare_result = b.month - a.month;
                    return compare_result;
                })
            }

            //-----------------------------------------
            // メソッド（APIコールなし・テンプレート内使用あり）
            //-----------------------------------------
            function getCategoryName(category_id) {
                const category = categories.value.find(category => category.id === category_id);
                return category ? category.name : null;
            }

            //-----------------------------------------
            // メソッド（APIコールあり・テンプレート内使用なし）
            //-----------------------------------------
            function getArticle() {
                const article_id = useRoute().params.article_id;
                axios.get(`${httpUtility.getBackendAPIUrl(blogPathConfig.ARTICLE_API_PATH)}/${article_id}`)
                .then(response => {
                    article.value = response.data["article"];
                    fixed_article_text.value = fixArticleText(article.value.text);
                    document.title = titleUtility.getPageTitle(article.value.title);
                })
                .catch(() => {
                    router.push({name: "error"});
                });
            }

            function getCategories() {
                axios.get(`${httpUtility.getBackendAPIUrl(blogPathConfig.CATEGORY_API_PATH)}`)
                .then(response => {
                    categories.value = response.data["categories"];
                    sortBySort(categories.value);
                })
                .catch(() => {
                    router.push({name: "error"});
                });
            }

            function getYearmonths() {
                axios.get(`${httpUtility.getBackendAPIUrl(blogPathConfig.YEARMONTH_API_PATH)}`)
                .then(response => {
                    yearmonths.value = response.data["yearmonths"];
                    sortByYearmonth(yearmonths.value);
                })
                .catch(() => {
                    router.push({name: "error"});
                });
            }

            // -----------------------------------------
            // テンプレートへの返り値
            // -----------------------------------------
            return {
                getDatetimeString: datetimeUtility.getDatetimeString,
                article, categories, fixed_article_text, yearmonths,
                getCategoryName,
            };
        }
    };
</script>

<style scoped lang="scss">
    @import "@/scss/views/blog/article.scss";
</style>